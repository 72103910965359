<template>
    <div id="add-price-list-intercountry" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 ref="myheader" class="modal-title" id="myModalLabel2">New InterCountry Price List</h4>
                    <button ref="add_intercountry_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <label>Between</label>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group">
                                    <label>Select Nomenclature</label>
                                    <select v-model="selectedNomenclatureID1" class="form-control">
                                        <option value="">Select Nomenclature</option>
                                        <option v-for="nomenclature in interCountryNomenclatureList" :key="nomenclature.id" :value="nomenclature.id">{{nomenclature.name}}</option>
                                    </select>
                                    <p class="error" v-if="selectedNomenclatureID1Error">{{selectedNomenclatureID1ErrorMessage}}</p>
                                </div>
                            </div>
                            <div class="col-2">And</div>
                            <div class="col-5">
                                <div class="form-group">
                                    <label>Select Nomenclature</label>
                                    <select v-model="selectedNomenclatureID2" class="form-control">
                                        <option value="">Select Nomenclature</option>
                                        <option v-for="nomenclature in interCountryNomenclatureList" :key="nomenclature.id" :value="nomenclature.id">{{nomenclature.name}}</option>
                                    </select>
                                    <p class="error" v-if="selectedNomenclatureID2Error">{{selectedNomenclatureID2ErrorMessage}}</p>
                                </div>
                            </div>
                        </div>

                        
                        <div class="form-group">
                            <label>Weights</label>
                            <div v-for="(weightPrice,index) in loadedWeightPrice" :key="index" class="row">
                                <div class="col-5">
                                    <select v-model="loadedWeightPrice[index].weight_id" class="form-control">
                                        <option value="">Select Weight</option>
                                        <option v-for="weight in supportedWeightList" :key="weight.id" :value="weight.id" :selected="weightPrice.weight_id==weight.id">{{weight.weight}}</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <input v-model="loadedWeightPrice[index].price" type="number" class="form-control"  placeholder="Price"/>
                                </div>
                                <div class="col-2">
                                    <button @click="deleteWeight(index)" type="button" v-if="index!=0" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-info" @click="addWeightToDom">Add Weight</button>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveNewInterCountryPriceList">Save<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
import { mapGetters } from 'vuex';
export default {
  name: 'NewPriceListInterCountry',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)

    this.loadSupportedWeightList();
    this.loadInterCountryNomenclatureList();
  },
  computed: {
        ...mapGetters('region',{
            interCountryNomenclatureList: 'retrieveInterCountryNomenclatures',
        })
    },
  data () {
    return {
      selectedNomenclatureID1: 0,
      selectedNomenclatureID2: 0,
      selectedNomenclatureID1Error: false,
      selectedNomenclatureID1ErrorMessage: '',
      selectedNomenclatureID2Error: false,
      selectedNomenclatureID2ErrorMessage: '',

      supportedWeightList: [],

      loadedWeightPrice: [],

      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {

    async loadSupportedWeightList() {
        const weightListResponse = await repository.regions.fetchSupportedWeightList();
        if (weightListResponse.success){
            this.supportedWeightList = weightListResponse.data;
            return;
        }
    },

    addWeightToDom() {
        if (this.loadedWeightPrice.length < this.supportedWeightList.length){
            this.loadedWeightPrice.push({
                "weight_id":"",
                "price":""
            })
        }
    },
    deleteWeight(index){
        this.loadedWeightPrice.splice(index,1)
    },

    async saveNewInterCountryPriceList () {
      if (this.loading) return
      this.resetData()
      const newInterCountryPriceListValidation = this.validateNewInterCountryPriceList()
      if (!newInterCountryPriceListValidation.success) return
      this.loading = true
      const data = {
        nomenclature1ID: this.selectedNomenclatureID1,
        nomenclature2ID: this.selectedNomenclatureID2,
        weightIDAndPriceMap: this.loadedWeightPrice,
      }
      const response = await repository.regions.createNewInterCountryPriceList(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Price List Created');
        return
      }
      this.showErrorMessage(response.data)
    },



    validateNewInterCountryPriceList () {
        const returnValue = new Object()
        if (!this.selectedNomenclatureID1) {
            this.selectedNomenclatureID1Error = true
            this.selectedNomenclatureID1ErrorMessage = 'Select a valid Nomenclature'
            returnValue.success = false
            return returnValue
        }
        if (!this.selectedNomenclatureID2) {
            this.selectedNomenclatureID2Error = true
            this.selectedNomenclatureID2ErrorMessage = 'Select a valid Nomenclature'
            returnValue.success = false
            return returnValue
        }
        // if (!this.mapWeightToPrice()) {
        //     returnValue.success = false
        //     return returnValue
        // }

        if (this.loadedWeightPrice.length==0){
            alert('Weight And Price Not Selected');
            returnValue.success=false;
            return returnValue;
        }else{
            let check = new Map();
            for (let weightPrice of this.loadedWeightPrice){
                if (!weightPrice.weight_id || !weightPrice.price){
                    alert("Missing or Invalid Values Exist. Check Weight And Prices")
                    returnValue.success=false;
                    return returnValue;
                }
                if (check.has(weightPrice.weight_id)){
                    alert("Duplicate weight selected")
                    returnValue.success=false;
                    return returnValue;
                }
                check.set(weightPrice.weight_id,"")
            }
        }

      returnValue.success = true
      return returnValue
    },



      
    loadInterCountryNomenclatureList(){
        repository.regions.loadInterCountryNomenclatureList();
    },
    mapWeightToPrice(){
        this.selectedWeightIDPriceMap = {};
        for (var x of this.addedWeightCount){
        let price = this.$refs[`price_for_${x}`].value;
        let weightID = this.$refs[`weight_for_${x}`].value;

        if (!weightID || !price){
            alert("Values missing! Check your weight and prices")
            return false;
        }

        if (weightID in this.selectedWeightIDPriceMap){
            alert("Duplicate Weight Selected");
            return false;
        }
        this.selectedWeightIDPriceMap[weightID] = price;
        }
        return true;
    },
      
    

    

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.add_intercountry_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.selectedNomenclatureID1Error=false;
      this.selectedNomenclatureID2Error=false;
      this.success = false;
      this.successMessage = '';
      this.error = false;
      this.errorMessage = '';
    },

  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

