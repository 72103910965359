<template>
    <div :id="`update-price-list-intercity-${index}`" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 ref="myheader" class="modal-title" :id="`myModalLabel2-${index}`">Update InterCity Price List</h4>
                    <button :id="`update_${index}_intercity_close_button`" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <label>Between</label>
                        <div class="row">
                            <div class="col-5">
                                <div class="form-group">
                                    <label>Select Nomenclature</label>
                                    <select class="form-control" disabled>
                                        <option>{{interCityPriceData.nomenclature1_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">And</div>
                            <div class="col-5">
                                <div class="form-group">
                                    <label>Select Nomenclature</label>
                                    <select class="form-control" disabled>
                                        <option>{{interCityPriceData.nomenclature2_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        
                        <div class="form-group">
                            <label>Weights</label>
                            <div v-for="(weightPrice,index) in loadedWeightPrice" :key="index" class="row">
                                <div class="col-5">
                                    <select v-model="loadedWeightPrice[index].weight_id" class="form-control">
                                        <option value="">Select Weight</option>
                                        <option v-for="weight in supportedWeightList" :key="weight.id" :value="weight.id" :selected="weightPrice.weight_id==weight.id">{{weight.weight}}</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <input v-model="loadedWeightPrice[index].price" type="number" class="form-control"  placeholder="Price"/>
                                </div>
                                <div class="col-2">
                                    <button @click="deleteWeight(index)" type="button" v-if="index!=0 && isRoleAllowedDelete" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-info" @click="addWeightToDom">Add Weight</button>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-md-6">
                            <button v-if="isRoleAllowedDelete" type="button" class="btn btn-info" @click="updateInterCityPriceList">Update<span v-if="loading" class="spinner-border text-white"></span></button>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <button v-if="isRoleAllowedDelete" type="button" class="btn btn-outline btn-danger" @click="deleteInterCityPriceList">DELETE PRICE LIST<span v-if="deleteLoading" class="spinner-border text-red"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
    props: ['interCityPriceData', 'index'],
    mounted(){
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
        document.head.appendChild(sweetAlert)
        
        this.loadSupportedWeights()
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,

            supportedWeightList: [],
            loadedWeightPrice: [],

            deleteLoading:false,
            loading: false,
            error: false,
            errorMessage: false,
        }
    },
    computed: {
        isRoleAllowedDelete(){
            return repository.adminIsLord()
        },
    },
    methods: {
        async loadSupportedWeights() {
            const weightListResponse = await repository.regions.fetchSupportedWeightList();
            if (weightListResponse.success){
                this.supportedWeightList = weightListResponse.data;
                this.showWeightPrice();
                return;
            }
        },

        showWeightPrice() {
            for (var supportedWeight of this.supportedWeightList) {
                for (var cityWeight of this.interCityPriceData.price_weight_information){
                    if (supportedWeight.id==cityWeight.supported_weight_id){
                        this.loadedWeightPrice.push({
                            "weight_id": cityWeight.supported_weight_id,
                            "price": cityWeight.price
                        })
                    }
                }
            }
        },
        addWeightToDom() {
            if (this.loadedWeightPrice.length < this.supportedWeightList.length){
                this.loadedWeightPrice.push({
                    "weight_id":"",
                    "price":""
                })
            }
        },
        deleteWeight(index){
            this.loadedWeightPrice.splice(index,1)
        },

        async deleteInterCityPriceList() {
            if (this.deleteLoading) return;
            if (confirm('Are you sure you want to delete this price list? This action cannot be reversed')) {
                this.deleteLoading = true;
                const data = {
                    nomenclature1ID: this.interCityPriceData.nomenclature1_id,
                    nomenclature2ID: this.interCityPriceData.nomenclature2_id,
                }
                const response = await repository.regions.deleteInterCityPriceList(data);
                this.deleteLoading = false;
                // if (response.success){
                    // let closeButton = document.querySelector(`#update_${this.index}_intercity_close_button`);
                    // closeButton.click();
                    $.toast({
                        heading: 'Deleted',
                        text: 'Price List Deleted',
                        position: 'top-right',
                        loaderBg: '#ff6849',
                        icon: 'success',
                        hideAfter: 3000,
                        stack: 6
                    });
                // }
            }
        },






        async updateInterCityPriceList () {
            if (this.loading) return
            this.resetData()
            const updateInterCityPriceListValidation = this.validateUpdateInterCityPriceList()
            if (!updateInterCityPriceListValidation.success) return
            this.loading = true
            
            const data = {
                nomenclature1ID: this.interCityPriceData.nomenclature1_id,
                nomenclature2ID: this.interCityPriceData.nomenclature2_id,
                weightIDAndPriceMap: this.loadedWeightPrice,
            }
            console.log(data)
            const response = await repository.regions.updateInterCityPriceList(data)
            this.loading = false
            if (response.success) {
                this.resetData()
                this.showSuccessMessage('Price List Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

        validateUpdateInterCityPriceList () {
            const returnValue = new Object()
            if (this.loadedWeightPrice.length==0){
                alert('Weight And Price Not Selected');
                returnValue.success=false;
                return returnValue;
            }else{
                let check = new Map();
                for (let weightPrice of this.loadedWeightPrice){
                    if (!weightPrice.weight_id || !weightPrice.price){
                        alert("Missing or Invalid Values Exist. Check Weight And Prices")
                        returnValue.success=false;
                        return returnValue;
                    }
                    if (check.has(weightPrice.weight_id)){
                        alert("Duplicate weight selected")
                        returnValue.success=false;
                        return returnValue;
                    }
                    check.set(weightPrice.weight_id,"")
                }
            }

            returnValue.success = true
            return returnValue;
        },



        showSuccessMessage (message) {
            let closeButton = document.querySelector(`#update_${this.index}_intercity_close_button`);
            closeButton.click();
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },





        resetData () {
            this.error = false;
            this.errorMessage = '';
        },
    }
}
</script>

