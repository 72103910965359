<template>
     <!-- Main content -->
    <section class="content-wrapper">
        <section class="content-header">
            <h1>Price Listing</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/'}">Home</router-link></li>
                <li class="breadcrumb-item active">Price Listing</li>
            </ol>
        </section>
		<div class="row">
            <div class="col-12">
                <div class="row">
                    
                </div>
                <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterCity</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-price-list-intercity">Add</button>
                                            <NewPriceListInterCity/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="(interCityPriceData, index) in interCityPriceList" :key="index">
                                        <a  class="media media-single" href="#" data-toggle="modal" :data-target="`#update-price-list-intercity-${index}`">
                                            <span class="title">{{interCityPriceData.nomenclature1_name}} - {{interCityPriceData.nomenclature2_name}}</span>
                                            <!-- <span class="badge badge-pill badge-secondary"></span> -->
                                        </a>
                                        <UpdatePriceListInterCity :interCityPriceData="interCityPriceData" :index="index"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterState</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-price-list-interstate">Add</button>
                                            <NewPriceListInterState/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="(interStatePriceData, index) in interStatePriceList" :key="index">
                                        <a  class="media media-single" href="#" data-toggle="modal" :data-target="`#update-price-list-interstate-${index}`">
                                            <span class="title">{{interStatePriceData.nomenclature1_name}} - {{interStatePriceData.nomenclature2_name}}</span>
                                            <!-- <span class="badge badge-pill badge-secondary"></span> -->
                                        </a>
                                        <UpdatePriceListInterState :interStatePriceData="interStatePriceData" :index="index"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterCountry</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-price-list-intercountry">Add</button>
                                            <NewPriceListInterCountry/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided">
                                    <div  v-for="(interCountryPriceData, index) in interCountryPriceList" :key="index">
                                        <a  class="media media-single" href="#" data-toggle="modal" :data-target="`#update-price-list-intercountry-${index}`">
                                            <span class="title">{{interCountryPriceData.nomenclature1_name}} - {{interCountryPriceData.nomenclature2_name}}</span>
                                            <!-- <span class="badge badge-pill badge-secondary"></span> -->
                                        </a>
                                        <UpdatePriceListInterCountry :interCountryPriceData="interCountryPriceData" :index="index"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <!--
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterState</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-price-list-interstate">Add</button>
                                            <NewPriceListInterState/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided inner-user-div">
                                    <div  v-for="(interStatePriceData, index) in interStatePriceList" :key="index">
                                        <a  class="media media-single" href="#" data-toggle="modal" :data-target="`#update-price-list-interstate-${index}`">
                                            <span class="title">{{interStatePriceData.nomenclature1_name}} - {{interStatePriceData.nomenclature2_name}}</span>

                                        </a>
                                        <UpdatePriceListInterState :priceListData="interStatePriceData" :index="index"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 mt-30">
                        <div class="box">
                            <div class="box-header with-border">
                                <div class="">
                                    <div class="media">
                                        <div class="media-body">
                                            <h5 class="box-title">InterCountry</h5>
                                        </div>
                                        <div class="media-right">
                                            <button class="btn btn-block btn-success btn-sm"  data-toggle="modal" data-target="#add-intercountry">Add</button>
                                            <NewNomenclatureInterCountry/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-body p-0">
                                <div class="media-list media-list-hover media-list-divided inner-user-div">
                                    <div  v-for="interCountryNomenclature in interCountryNomenclatureList" :key="interCountryNomenclature.id">
                                        <a  class="media media-single" href="#"   data-toggle="modal" :data-target="`#update-intercountry-${interCountryNomenclature.id}`">
                                            <span class="title">{{interCountryNomenclature.name}}</span>
                                            <span class="badge badge-pill badge-secondary">{{ interCountryNomenclature.count }}</span>
                                        </a>
                                        <UpdateNomenclatureInterCountry :nomenclatureData="interCityNomenclature"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                   
                    
                    
                </div>
            </div>
		</div>
    </section>
</template>

<script>
import NewPriceListInterCity from '../../components/Region/NewPriceListInterCity'
import UpdatePriceListInterCity from '../../components/Region/UpdatePriceListInterCity'
import NewPriceListInterState from '../../components/Region/NewPriceListInterState'
import UpdatePriceListInterState from '../../components/Region/UpdatePriceListInterState'
import NewPriceListInterCountry from '../../components/Region/NewPriceListInterCountry'
import UpdatePriceListInterCountry from '../../components/Region/UpdatePriceListInterCountry'
import { mapGetters } from 'vuex';
import { repository } from '../../presenter'
export default {
    components:{
        NewPriceListInterCity,
        UpdatePriceListInterCity,
        NewPriceListInterState,
        UpdatePriceListInterState,
        NewPriceListInterCountry,
        UpdatePriceListInterCountry,
    },
    computed: {
        ...mapGetters('region',{
            interCityPriceList: 'retrieveInterCityPriceList',
            interStatePriceList: 'retrieveInterStatePriceList',
            interCountryPriceList: 'retrieveInterCountryPriceList',
        })
    },
    data() {
        return{
            publicPath: process.env.BASE_URL,
        }
    },
    mounted() {
        const listJS = document.createElement('script')
        listJS.setAttribute('src', `${this.publicPath}assets/js/pages/list.js`)
        document.head.appendChild(listJS)

        this.loadInterCityPriceList();
        this.loadInterStatePriceList();
        this.loadInterCountryPriceList();
    },
    methods: {
        loadInterCityPriceList(){
            repository.regions.loadInterCityPriceList();
        },
        loadInterStatePriceList(){
            repository.regions.loadInterStatePriceList();
        },
        loadInterCountryPriceList(){
            repository.regions.loadInterCountryPriceList();
        },
    }
}
</script>

